<template>
  <div class="dealDetail">
    <div class="breadcrumbOutside">
      <div class="breadcrumbBox">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>
            <a @click="prev">化肥农药交易</a>
          </el-breadcrumb-item>
          <el-breadcrumb-item>商品详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="mainBox">
      <div class="dealDetail_top">
        <el-row>
          <el-col :span="8">
            <div class="ddt_imgBox">
              <div class="ddt_ib_showImg">
                <img :src="`${showImg.replace('nanfan-mall', 'nanfanmall')}`" alt />
              </div>
              <div class="ddt_ib_btn">
                <ul class="ddt_ib_btnBox">
                  <li class="ddt_ib_btnBlock" v-for="(item,index) in detailList.imgList" :key="index">
                    <img  :src="`${item.replace('nanfan-mall', 'nanfanmall')}`" alt
                      @click="changeImg(item)" />
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :span="16">
            <el-row class="ddt_goodsName">{{detailList.goodsName}}</el-row>
            <el-row class="ddt_defaultRow ddt_goodsName2">
              <span class="ddt_spanBlock">价格：</span><span class="ddt_priceBol">￥</span>
              <span class="ddtb_bigfont ddtb_bigfont2">{{detailList.shopPrice}}</span>
            </el-row>
            <el-row class="ddt_defaultRow ddt_defaultRow2">
              <p class="ddt_onHirTime"><span class="ddt_spanBlock">销量：</span>{{detailList.salesSum}}</p>
              <span>当前库存：{{detailList.storeCount}}</span>
            </el-row>
            <el-row class="ddt_defaultRow ddt_goodsName2">
              <span class="ddt_spanBlock">运费信息：</span>
              <span>包邮</span>
            </el-row>
            <el-row class="ddt_defaultRow ddt_goodsName2">
              <span class="ddt_spanBlock">服务：</span>
              <span>由{{ detailList.storeName}}提供服务</span>
            </el-row>
            <el-row class="ddt_defaultRow ddt_goodsName2">
              <span class="ddt_spanBlock">服务承诺：</span>
              <div class="ddt_lable">
                <span class="ddt_lable_span"><i class="el-icon-circle-check ddt_icon"></i>承诺在线交易</span>
                <span class="ddt_lable_span"><i class="el-icon-circle-check ddt_icon"></i>交易保障</span>
                <span class="ddt_lable_span"><i class="el-icon-circle-check ddt_icon"></i>虚假价格赔付</span>
                <span class="ddt_lable_span"><i class="el-icon-circle-check ddt_icon"></i>100%现货</span>
                <span class="ddt_lable_span"><i class="el-icon-circle-check ddt_icon"></i>一贵就赔</span>
                <span class="ddt_lable_span"><i class="el-icon-circle-check ddt_icon"></i>货不对板赔付</span>
                <span class="ddt_lable_span"><i class="el-icon-circle-check ddt_icon"></i>少货赔付</span>
                <span class="ddt_lable_span"><i class="el-icon-circle-check ddt_icon"></i>未按时发货赔付</span>
              </div>
              
            </el-row>
            
            <el-row class="ddt_BtnBox">
              <div class="ddt_phoneBtn" @click="toApp">打电话</div>
              <div class="ddt_buyBtn" @click="toApp">立即{{btnShow}}</div>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <div class="dealDetail_bottom clearfix">
        <div class="ddb_left ddb_left2">
          <div class="ddb_left_top2">
            <div class="ddb_lt_tit">商家</div>
            <div class="ddb_lt_shoplogoBox2">
              <!-- <div class="ddb_lts_logo">
                <img
                  src="../../../public/static/img/market/headerPic.png"
                  alt
                  class="ddb_lts_logo_Img"
                />
              </div> -->
              <div class="ddb_lts_name ddb_lts_name2">{{detailList.storeName}}</div>
            </div>
            <!-- <div class="ddb_lts_sellerMsg">
              <el-row>
                <p>
                  身份认证：
                  <img src="../../../public/static/img/market/ql@2x.png" />
                  <img src="../../../public/static/img/market/sf@2x.png" />
                </p>
              </el-row>
              <el-row>
                <p>经营模式：批发商</p>
              </el-row>
              <el-row>
                <p>
                  商家等级：
                  <img src="../../../public/static/img/market/sjdj@2x.png" />
                </p>
              </el-row>
            </div> -->
            <div class="ddb_lts_Btn">
              <div class="ddb_lts_contactBtn" @click="toApp">联系商家</div>
              <div class="ddb_lts_toShopBtn" @click="toApp">进店逛逛</div>
            </div>
          </div>
          <div class="ddb_left_bottom2">
            <div class="ddb_lb_tit">商家在卖</div>
            <div
              class="ddb_lb_block"
              v-for="(item,index) in alsoSell"
              :key="index"
              @click="toOther(item.id)"
            >
              <div class="ddb_lb_block_top" v-if="index<2">
               
                <img
                  :src="`${item.imgList[0].replace('nanfan-mall', 'nanfanmall')}` || 'http://photocdn.sohu.com/20120323/Img338614056.jpg'"
                  alt />
              </div>
              <div class="ddb_lb_block_bottom" v-if="index<2">
                <el-row class="ddb_lb_bb_name">{{item.goodsName}}</el-row>
                <el-row class="ddb_lb_bb_price">￥{{item.shopPrice}}元</el-row>
              </div>
            </div>
          </div>
        </div>
        <div class="ddb_right ddb_right3">
          <el-row>
            <div class="ddb_right_top">商品详情</div>
          </el-row>
          <div class="ddb_right_main">
            <!-- <el-row class="ddbr_block_title">商品详情</el-row> -->
            <el-row class="ddbr_block_p">
              <p>{{detailList.goodsRemark}}</p>
            </el-row>
            <div class="ddbr_bigImg" v-for="(item, index) in detailList.imgList" :key="index">
              <img :src="item" alt />
            </div>

            <el-row class="ddbr_block_title">优质推荐</el-row>
            <el-row class="ddbr_block_p">
                <div class="ddb_lb_block" v-for="(item,index) in alsoSell" :key="index" @click="toOther(item.id)">
                  <el-col :span="6">
                    <div class="ddb_lb_block_top">
                     
                      <img
                        :src="`${item.imgList[0].replace('nanfan-mall', 'nanfanmall')}` || 'http://photocdn.sohu.com/20120323/Img338614056.jpg'"
                        alt />
                    </div>
                    <div class="ddb_lb_block_bottom ddb_lb_block_bottom2">
                      <el-row class="ddb_lb_bb_price ddb_lb_bb_labs">￥{{item.shopPrice}}元</el-row>
                      <el-row class="ddb_lb_bb_name ddb_lb_bb_name2 ddb_lb_bb_labs">{{item.goodsName}}</el-row>
                    </div>
                  </el-col>
                </div>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../../../src/pages/market/market.css";
export default {
  data() {
    return {
      id: this.$route.query.id,
      primaryMenuId: this.$route.query.pid,
      detailList: [],
      alsoSell: [],
      specification: [],
      updataTime: "",
      showImg: "",
      price: "",
      btnShow: ""
    };
  },
  mounted() {
    this.marketDetailList();
    this.marketRandomList();
    this.showWhichBtn();
  },
  methods: {
    prev() {
      this.$router.push({
        path: "/agriculturalAssets",
        query: {
          id: '1'
        }
      });
    },
    showWhichBtn() {
      const nowpath = this.$route.query.flag;
      switch (nowpath) {
        case "buy":
          this.btnShow = "采购";
          break;
        case "rent":
          this.btnShow = "租用";
          break;
      }
    },
    toApp() {
      this.$router.push({
        path: "/downLoad"
      });
    },
    changeImg(num) {
      this.showImg = num;
    },
    timestampToTime(val) {
      const date = new Date(parseInt(val)), //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        Y = date.getFullYear() + "-",
        M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-",
        D = date.getDate() + " ";
      return Y + M + D;
    },
    async marketRandomList() {
      const api = this.$fetchApi.marketPageList.api;
      
      const data = await this.$fetchData.fetchPost({
          catId1: this.primaryMenuId,
          pageNum: 1,
          pageSize: 4,
          recommend: true
        }, api, "json" );
      if (data.code === "200" && data.result) {
        this.alsoSell = data.result.rows;
      }
    },
    async marketDetailList() {
      
      // const api = this.$fetchApi.marketDetailList.api + this.id + "/2";
      const api = this.$fetchApi.marketGoodsDetailList.api + this.id ;

      const data = await this.$fetchData.fetchGet({}, api, "json");
      if (data.code === "200" && data.result) {
        this.detailList = data.result;
        this.updataTime = this.timestampToTime(data.timestamp);
        this.showImg = this.detailList.imgList[0];
        this.specification = this.detailList.specification;
        if (this.detailList.specification != null) {
          this.price = this.detailList.specification[0].value;
        } else {
          this.price = "价格面议";
        }
      }
    },
    toOther(id) {
      this.$router.push({
        path: "/market/agriculturalAssetsDetail",
        query: {
          id,
          pid: this.primaryMenuId,
          flag: this.$route.query.flag
        }
      });
    },
    changePrice(val) {
      this.price = val;
    }
  }
};
</script>
<style scoped>
</style>